import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { ListItem, ListItemIcon, List } from '@mui/material';
import { Grid } from 'semantic-ui-react';
import { SUI_GRID_CENTERED } from 'src/utils/semantic-utils';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { getEnvUrls } from 'src/utils/route-utils';
import { removeTrailingSlash } from 'src/utils/string-utils';

const items = [
    '1000+ on-demand and live workouts clinically proven to deliver more strength and energy than a traditional workout.',
    'Patented resistance equipment that tones and sculpts your whole body.',
    'Special classes for various life phases like pregnancy and menopause.',
];
const ENV_URLS = getEnvUrls();
const link = `${removeTrailingSlash(
    ENV_URLS.shopifyUrl || ''
)}?utm_source=web_app&utm_medium=web_app&utm_campaign=anonymous_user_experience`;

const Styled = {
    BannerContainer: styled(Box)(({ theme }) => ({
        padding: theme.spacing(2),
        background: theme.palette.brandColors.white,
    })),
    Title: styled(Typography)(() => ({
        margin: '0px !important',
    })),
    Subtitle: styled(Typography)(({ theme }) => ({
        marginTop: theme.spacing(1),
    })),
    List: styled(List)(({ theme }) => ({
        marginTop: theme.spacing(2),
        padding: 0,
    })),
    ListItem: styled(ListItem)(({ theme }) => ({
        alignItems: 'flex-start',
        '& + &': {
            marginTop: theme.spacing(1),
        },
    })),
    ListItemIcon: styled(ListItemIcon)(({ theme }) => ({
        marginRight: theme.spacing(1),
        marginTop: '0.2em',
        minWidth: 'auto',
    })),
    Button: styled(Button)(({ theme }) => ({
        marginTop: theme.spacing(2),
        '&:hover': {
            color: theme.palette.brandColors.white,
        },
    })),
};
const { widths } = SUI_GRID_CENTERED;

type NewToPvolveBannerProps = {};

export const NewToPvolveBanner = ({}: NewToPvolveBannerProps) => {
    return (
        <Grid container centered>
            <Grid.Row>
                <Grid.Column
                    mobile={widths.mobile}
                    tablet={widths.tablet}
                    computer={widths.computer}
                >
                    <Styled.BannerContainer>
                        <Styled.Title textAlign="center" variant="h5">
                            New to Pvolve?
                        </Styled.Title>
                        <Styled.Subtitle textAlign="center" variant="body1" fontWeight="bold">
                            Explore our equipment + streaming bundles to get started.
                        </Styled.Subtitle>
                        <Styled.List>
                            {items.map((item, index) => (
                                <Styled.ListItem key={item} disablePadding>
                                    <Styled.ListItemIcon>
                                        <CheckCircleOutlineOutlinedIcon color="tertiary" />
                                    </Styled.ListItemIcon>
                                    <Typography key={index} variant="body2">
                                        {item}
                                    </Typography>
                                </Styled.ListItem>
                            ))}
                        </Styled.List>
                        <Styled.Button fullWidth variant="contained" color="primary" href={link}>
                            Get Started
                        </Styled.Button>
                    </Styled.BannerContainer>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
