import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IState } from '@pvolve/sdk/src/redux/selectors';

import Selectors from 'src/state/root-selectors';
import EmailForm from 'src/components/auth/EmailForm';
import SEO from 'src/components/SEO';
import withLocation from 'src/components/withLocation';
import { SUI_GRID_CENTERED } from 'src/utils/semantic-utils';
import * as authStyles from 'src/styles/auth.module.scss';

import Typography from '@mui/material/Typography';
import { NewToPvolveBanner } from 'src/components/new-to-pvolve-banner/NewToPvolveBanner';

const connector = connect((state: IState) => ({
    activationCodeProduct: Selectors.subscriptions.setActivationCode.product(state),
}));

interface ContinueAuthPageProps extends ConnectedProps<typeof connector> {
    location: {
        state: {
            activationCode: string;
            checkout: boolean;
        };
    };
}

export const ContinueAuthPage = ({ activationCodeProduct, location }: ContinueAuthPageProps) => {
    const activationCode = location?.state?.activationCode;

    const checkout = location?.state?.checkout;

    const { widths } = SUI_GRID_CENTERED;

    return (
        <div className={authStyles.continueWrapper}>
            <SEO title="Pvolve Continue to Login or Signup" />
            {/* <SquareCloseButton /> */}
            {/* <div className={authStyles.socialButtonsWrapper}>
                <Grid container centered>
                    {activationCodeProduct && activationCode && (
                        <Grid.Row>
                            <Grid.Column
                                mobile={widths.mobile}
                                tablet={widths.tablet}
                                computer={widths.computer}
                            >
                                <div className={`${authStyles.confirmationMsg}`}>
                                    <Icon name="pv-checkmark-circle" color="sea" size={10} />
                                    <p className="color-pv-sea p3">
                                        Code valid for {activationCodeProduct.name} streaming
                                        membership. Create your account below to get started.
                                    </p>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    {checkout && (
                        <p className="color-pv-white p3">
                            Log in or create your account to complete your order.
                        </p>
                    )}
                </Grid>
            </div> */}
            <div className={authStyles.formContainer}>
                <Typography variant="h5">Log into your account</Typography>
                <EmailForm />
                <NewToPvolveBanner />
            </div>
        </div>
    );
};

export default connector(withLocation(ContinueAuthPage));
